.App {
  width: 100%;
  font-family: 'ShareTech', Courier, monospace;

}

p {
  margin: 0;
}

@font-face {
  font-family: 'ShareTech';
  src: url('/public/fonts/ShareTech-Regular.ttf');
}

.hero_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('/public/img/bg-mobile.png');
  background-size: cover;
  font-size: 22px;

}

@media screen and (min-width:800px) {
  .hero_container {
    font-size: 5vw;
    background-image: url('/public/img/bg.png');
  }
}

.hero_headline {
  /* font-size: 5vw; */
  font-weight: bold;
  color: whitesmoke;
}

.feature_container {
  width: 100%;
  /* height: 100vh; */
  /* background-color: whitesmoke; */
  background-image: url('/public/img/bg-8.jpg');
  background-size: cover;
}

.feature_headline {
  font-size: large;
  padding: 10px;
  margin: 0;
  color: whitesmoke;
}

@media screen and (min-width:800px) {
  .feature_headline {
    font-size: 2vw;
    padding-top: 30px;
    padding-left: 30px;
  }
}

.cards_container {
  /* background-color: bisque; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 20px;
  gap: 10px;
}

@media screen and (min-width:800px) {
  .cards_container {
    padding-top: 50px;
    padding-bottom: 100px;

  }
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.card_headline {
  font-size: medium;
  margin: 0;
  padding: 0;
  color: whitesmoke;

}

@media screen and (min-width:800px) {
  .card_headline {
    font-size: 1.5vw;

  }
}

.card {
  --a: 8deg;
  /* control the angle of rotation (the smaller, the better) */
  width: 150px;
  height: 200px;
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 10px;
  transform: perspective(400px) rotate3d(var(--r, 1, -1, 0), calc(var(--i, 0)*var(--a)));
  -webkit-mask:
    linear-gradient(135deg, #000c 40%, #000, #000c 60%) 100% 100%/250% 250%;
  transition: .4s;
  cursor: pointer;
}

.card:hover {
  --i: 1;
  -webkit-mask-position: 0 0;
}

@media screen and (min-width:800px) {
  .card {
    width: 24vw;
    height: 32vw;
  }
}

.footer_container {
  height: 40px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: whitesmoke;
  background-color: #171717;
}

@media screen and (min-width:800px) {
  .footer_container {
    padding-left: 40px;
    padding-right: 40px;
    font-size: large;
  }
  
}

.footer_img {
  width: 20px;
  border-radius: 3px;
}

.footer_contact {
  display: flex;
  align-items: center;
  gap: 4px;
  color: whitesmoke;
}